import React from 'react';
import { Success } from 'ekyc-web-module';
import Header from '../../components/Header';
import { OverrideGlobalStyle } from '../../styles/GlobalStyles';

const success = () => {
  return (
    <>
      <OverrideGlobalStyle />
      <Header align="left" />
      <Success />
    </>
  );
};

export default success;
